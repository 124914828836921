<template>
  <div class="center">
    <div class="title">
      商品图片<span class="red_title">(最多上传5张图片)</span>
    </div>
    <div class="img_list">
      <el-image
        :key="i"
        v-for="(item, i) in srcList"
        class="img_list_item"
        :src="item"
        fit="cover"
        :preview-src-list="srcList"
      >
      </el-image>
    </div>
    <div class="tacitly">默认为商品主图</div>
    <div class="table">
      <el-descriptions class="margin-top" :column="3" size="medium" border>
        <el-descriptions-item :span="3" label-class-name="my-label">
          <template slot="label"> 服务名称： </template>
          {{ allDate.name }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label">
          <template slot="label"> 服务类型： </template>
          {{ allDate.serviceType }}
        </el-descriptions-item>
        <el-descriptions-item :span="2" label-class-name="my-label">
          <template slot="label"> 状态： </template>
          {{
            !allDate.onShelf && allDate.applyStatus == 1
              ? "已下架"
              : applyStatus[allDate.applyStatus]
          }}
        </el-descriptions-item>
        <el-descriptions-item label-class-name="my-label">
          <template slot="label"> 价格： </template>
          {{
            allDate.price && allDate.unit
              ? allDate.price + "/" + allDate.unit
              : allDate.price
          }}
        </el-descriptions-item>
        <el-descriptions-item :span="2" label-class-name="my-label">
          <template slot="label"> 联系人： </template>
          {{ allDate.concateUser }}
        </el-descriptions-item>
        <el-descriptions-item
          :span="allDate.applyStatus == 0 ? 3 : 1"
          label-class-name="my-label"
        >
          <template slot="label"> 发布时间： </template>
          {{ allDate.createTime }}
        </el-descriptions-item>
        <el-descriptions-item
          v-if="allDate.applyStatus != 0"
          :span="2"
          label-class-name="my-label"
        >
          <template slot="label">
            {{
              !allDate.onShelf && allDate.applyStatus == 1
                ? "下架"
                : applyStatus[allDate.applyStatus]
            }}时间：
          </template>
          {{ allDate.updateTime }}
        </el-descriptions-item>
        <el-descriptions-item :span="3" label-class-name="my-label">
          <template slot="label"> 商家地址： </template>
          {{ allDate.address }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="lein"></div>
    <div class="label">
      <span></span>
      <div class="z">服务详情</div>
    </div>
    <div class="ang" v-html="allDate.content"></div>
    <div
      v-if="!this.type && !allDate.onShelf && allDate.applyStatus != 0"
      @click="del"
      class="bottom pointer"
    >
      删除商品
    </div>
    <div
      v-if="!this.type && allDate.onShelf && allDate.applyStatus == 1"
      @click="down"
      class="bottom pointer"
    >
      下架服务商品
    </div>
  </div>
</template>

<script>
import { serviceGooddetail, serviceGooddelete, onShelf } from "@/api/home";
const { baseUrl } = require("@/config/index.js");
export default {
  data() {
    return {
      applyStatus: ["待审批", "审批通过", "审批拒绝"],
      allDate: {},
      srcList: [],
      type: "",
    };
  },
  mounted() {
    this.getdetail();
    this.type = this.$route.query.type || "";
  },
  methods: {
    async getdetail() {
      const res = await serviceGooddetail({
        serviceGoodId: this.$route.query.id,
      });
      if (res.code == 0) {
        this.allDate = res.data;
        if (!res.data.price) {
          this.allDate.price = "面议";
          this.allDate.unit = "";
        }

        this.allDate.createTime = this.allDate.createTime.replace("T", " ");
        try {
          this.allDate.updateTime = this.allDate.updateTime.replace("T", " ");
        } catch (e) {
          e;
        }
        let arr = res.data.imgUrlList;
        arr.unshift(res.data.mainGraph);
        this.srcList = [...arr].map((item) => {
          return baseUrl + "third/third/ossGetFile/" + item;
        });
      } else {
        this.$message.error(res.message);
        this.$router.go(-1);
      }
    },
    del() {
      if (!this.beforhandle('personalCenter:publishedServices:del')) return;
      this.$confirm("确定要删除选中的商品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "zee-confirm",
      })
        .then(() => {
          serviceGooddelete({ serviceGoodIdList: [this.$route.query.id] }).then(
            (res) => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功",
                });
                this.$router.go(-1);
              }
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    down() {
      if (!this.beforhandle('personalCenter:publishedServices:offshelf')) return;
      this.$confirm("确定要下架选中的商品吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        customClass: "zee-confirm",
      })
        .then(() => {
          onShelf({
            onShelf: false,
            serviceGoodIdList: [this.$route.query.id],
          }).then((res) => {
            if (res.code == 0) {
              this.$message({
                type: "success",
                message: "下架成功",
              });
              this.getdetail();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消下架",
          });
        });
    },
  },
};
</script>
<style lang="scss">
.zee-confirm {
  .el-message-box {
    width: 450px !important;
    height: 218px !important;
    background: #ffffff !important;
    border-radius: 10px !important;
  }
  .el-message-box__header {
    background: #f8f8f8;
  }
  .el-message-box__title {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
  }
  .el-message-box__content {
    padding: 49px 119px;
    color: #606266;
    font-size: 14px;
  }
  .el-message-box__btns {
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
}
</style>
<style  lang="scss">
.my-label {
  width: 137px;
  text-align: right !important;
}
.ang {
  margin-top: 18px;
  margin-bottom: 8px;
  p {
    img {
      width: 100% !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.center {
  width: 1195px;
  background: #ffffff;
  padding: 22px 14px 12px;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .bottom {
    width: 308px;
    margin: 0 auto;
    height: 34px;
    line-height: 34px;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(90deg, #2079fb, #4cb2fc);
    border-radius: 4px;
  }
  .label {
    display: flex;
    align-items: center;
    height: 19px;
    font-size: 18px;
    line-height: 19px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    .z {
      height: 19px;
      font-size: 18px;
      line-height: 19px;
    }
    span {
      display: inline-block;
      width: 4px;
      height: 19px;
      background: #00a0ff;
      border-radius: 2px;
      margin-right: 9px;
    }
  }
  .lein {
    height: 1px;
    background: #0296ff;
    margin: 21px 0 15px;
  }
  .table {
    width: 803px;
    margin-left: 155px;
  }
  .title {
    padding-left: 15px;
    font-size: 16px;
    color: #333333;
  }
  .red_title {
    font-size: 12px;
    color: #ff0000;
  }
  .img_list {
    margin: 24px 0 13px;
    padding-left: 15px;
    .img_list_item {
      width: 148px;
      height: 148px;
      border-radius: 4px;
      margin-right: 19px;
    }
  }
  .tacitly {
    font-size: 16px;
    font-weight: 400;
    color: #666666;
    padding-left: 31px;
  }
  .ang {
    margin-top: 18px;
    margin-bottom: 8px;
    p {
      img {
        width: 100% !important;
      }
    }
  }
}
</style>>